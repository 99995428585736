/* CookieBanner.css */

.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    background-color: #fff;
    color: #333;
    border-top: 1px solid #ccc;
    z-index: 99999;
  }
  
  .cookie-banner .content {
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
  
  .cookie-banner .content .message {
    grid-column: span 8;
    color: #777777;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 400;
  }

  .cookie-banner .content .message .messageStrong {
    color: #333;
  }
  
  .messageLink{
    color: #777;
  }
  .cookie-banner .content .actions {
    display: flex;
    align-self: center;
    margin-top: 0.5rem;
    grid-column: span 4;
  }
  
  .cookie-banner .actions button {
    margin: 0.2rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  
  .cookie-banner .actions button:hover {
    opacity: 0.8;
  }
  
  .cookie-banner .actions button.accept-all {
    background-color: #002b7a; 
    color: white;
    border: none;
    border-radius: 9999px;
  }
  
  .cookie-banner .actions button.reject-non-essential {
    color: #666;
    border: 1px solid #ccc;
    border-radius: 9999px;
    display: flex;
    align-items: center;
  }
  
  .cookie-banner .actions button.reject-non-essential svg {
    transform: rotate(-90deg);
    width: 0.5625rem;
    fill: none;
  }
  
  .cookie-banner .actions button.reject-non-essential svg path {
    fill: currentColor;
  }
  
  .cookie-banner .actions button.reject-non-essential:hover {
    border-color: #aaa;
  }
  
  .cookie-banner .actions button.reject-non-essential:focus-visible {
    border-color: #ccc;
    outline: none;
  }
  
  .cookie-banner .actions button[disabled] {
    pointer-events: none;
    opacity: 0.6;
  }
  
  .cookie-banner .actions button.reject-non-essential.disabled {
    color: #999;
    border-color: #ccc;
  }
  
  .cookie-banner .actions button.reject-non-essential.disabled:hover {
    border-color: #ccc;
  }
  
  .cookie-banner .actions .separator {
    display: none;
  }

  @media screen and (max-width: 960px) {
    .cookie-banner .content .message {
      grid-column: span 12;
    }
    .cookie-banner .content .actions {
      grid-column: span 12;
    }

    .cookie-banner .actions .separator {
      display: block;
    }

    .cookie-banner .actions button {
      margin: 0.2rem 0.2rem 0.2rem 0;
    }

    .cookie-banner .actions button.accept-all {
      margin-left: 0;
    }

    .cookie-banner .actions button.reject-non-essential {
      margin-left: 0;
    }
    
  }