/* General styling for the gallery page */
.galleryPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

/* Styling for the filter buttons container */
.filterButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
}

/* Styling for each filter button */
.filterButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.filterButton.active {
    background-color: #0056b3;
}

.filterButton:hover {
    background-color: #0056b3;
}

/* Styling for the gallery section */
.gallerySection {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    width: 100%;
    max-width: 1200px;
}

/* Styling for each gallery item */
.galleryItem {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    padding-top: 75%; /* Aspect ratio of 4:3 */
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
}

.galleryItem:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Modal styling */
.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the modal is above other elements */
    transition: opacity 0.3s ease;
}

.modalContent {
    background: white;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    max-width: 90%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease;
}

.closeButton {
    position: absolute;
    top: 11px;
    right: 11px;
    background: #0056b3;
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    z-index: 1001;
}

.modalImageContainer {
    position: relative;
    width: 90%;
    height: 90%;
}

.modalImage {
    max-width: 100%;
    max-height: 70vh;
    object-fit: contain;
    border-radius: 10px;
    position: relative; /* Ensure positioning context */
}

.imageTitle {
    margin-top: 10px;
    font-size: 18px;
    text-align: center;
    color: #333;
}

.modalCollabContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.logo-icon {
    background-image: url(../../media/logo2.png);
    min-width: 176px;
    min-height: 38px;
    overflow: hidden;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 10px;
}

.imageCollab {
    min-width: 100px;
    min-height: 50px;
    max-width: 100px;
    max-height: 50px;
    overflow: hidden;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.fullscreenButton {
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    transition: background-color 0.3s;
    z-index: 1002; /* Ensure button is above other content */
}

.fullscreenButton:hover {
    background-color: #0056b3;
}