/* Estilos para la sección principal */
.privacy-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  .privacy-title {
    font-size: 2.5em;
    color: #333;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .privacy-subtitle {
    font-size: 1.5em;
    color: #555;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .privacy-content {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .privacy-content p {
    font-size: 1em;
    color: #333;
    margin-bottom: 10px;
  }
  
  .privacy-content a {
    color: #007BFF;
    text-decoration: none;
  }
  
  .privacy-content a:hover {
    text-decoration: underline;
  }
  
  /* Estilos para los acordeones */
  .privacy-accordion {
    margin-top: 20px;
  }
  
  .privacy-accordion-summary {
    background-color: #ffffff;
  }
  
  .privacy-accordion-details {
    padding: 20px;
  }

  .styled-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .styled-table th, .styled-table td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
  }
  
  .styled-table th {
    background-color: #f2f2f2;
  }