.contacto-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .contacto {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
  }
  
  .contacto h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .contacto-item {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
  }
  
  .contacto-icon {
    width: 30px;
    height: 30px;
    background-color: #007bff; /* Example color */
    border-radius: 50%;
    margin-right: 15px;
  }
  
  .contacto-text {
    font-size: 16px;
  }
  
  .time-schedule-text {
    font-size: 16px;
    line-height: 1.5;
  }
  
  .phone-text a,
  .email-text a {
    color: #333;
    text-decoration: none;
  }
  
  .map-container {
    margin-top: 20px;
    height: 300px; /* Adjust height as needed */
    border-radius: 8px;
    overflow: hidden;
    z-index: 100;
  }
  
  .map-container iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;
    z-index: 100;
  }