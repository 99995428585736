.nav-sticky {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 999;
  height: 100px;
}


.slogan-banner {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  background-color: #002b7a;
  border-radius: 0px 0px 0px 0px;
  align-items: center;
  justify-content: center;
}

.slogan-banner p{
  width: auto; /* 475px */
  height: auto; /* 21px */
  white-space: pre;
  font-style: normal;
  color: rgba(255, 255, 255, 0.85);
  font-size: 12px;
  letter-spacing: 0em;
  line-height: 1.2;
}

.languageButton{
  width: 156px;
  height: 26px;
  justify-content: center;
  align-items: center;
  background-color: #ffffffa1;
  overflow: hidden;
  padding: 0px 0px 0px 0px;
  align-content: center;
  gap: 10;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-left: 15px;
}

.languageButton:hover{

  background-color: rgb(255, 255, 255);
  color: #1c5aab;
  transition: all 0.3s ease-in-out;
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  background-color: #ffffff;
  position: absolute;
  width: 100%;
  z-index: 999;
}

.nav-menu ul {
  display: flex;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 24px;
}

.navbar-logo .navbar-logo-icon {
  background-image: url(../media/logo2.png);
  width: 176px;
  height: 38px;
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.contactButton{
  box-sizing: border-box;
  width: 120px;
  height: 29px;
  overflow: hidden;
  border-radius: 20px;
  border: 2px solid #1c5aab;
  background-color: #ffffff;
  font-weight: 500;
  font-style: normal;
  color: #1c5aab;
  cursor: pointer;
  font-size: 15px;
}

.contactButton:hover{
  border: 2px solid #1c5aab;
  background-color: #1c5aab;
  color: #fff;
  transition: all 0.3s ease-in-out;
}


.menu-icon {
  display: none;
}

.nav-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 998;
}

.nav-menu.active {
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 999;
}

.nav-item {
  margin-right: 16px;
}

.nav-links {
  color: #333;
  text-decoration: none;
  font-size: 18px;
  padding: 5px;
  transition: all 0.3s ease-in;
  font-size: 16px;
}

.nav-links:hover {
  text-decoration: underline;
}

.language-selector{
  margin-left: 10px;
}

.language-selector-button {
  border: none;
  background-color: #013eae;
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 12px;
  cursor: pointer;
  padding: 5px 10px;

}

@media screen and (max-width: 960px) {
  .navbar-container {
    justify-content: space-between;
  }

  .menu-icon {
    display: block;
    color: rgb(28, 28, 28);
    cursor: pointer;
    font-size: 24px;
    z-index: 999;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    position: absolute;
    top: 60px;

    left: -100%;
    opacity: 1;
    transition: all 0.5s ease-in-out;
    background-color: rgb(255, 255, 255);
    z-index: 998;
  }

  .nav-menu.active {
    left: 0;
    right: 0;
    opacity: 1;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }

  .nav-item {
    width: 100%;
    margin-right: 0;
    margin-top: 16px;
  }

  .nav-links {
    text-align: center;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    text-decoration: underline;
  }
}
