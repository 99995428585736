footer{
    background-color: #f4f4f4;
    padding: 25px;
    justify-content: space-between;
    display: flex;
    align-items: center;

}

.cpyright-legal{
    color: #767676;
    font-size: 12px;
}

.footer-link{
    color: #767676;
    text-decoration: none;
}

.footer-link:hover{
    text-decoration: underline;
}

.CookiesConfig{
    background-color: #002b7a; 
    color: white;
    border: none;
    border-radius: 9999px;
    margin: 0.2rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

/* Overlay and modal container */
.CookiesConfigOverlay {
    /* Overlay styles */
    background-color: rgba(0, 0, 0, 0.4); /* Adjust opacity as needed */
    backdrop-filter: blur(10px);
    z-index: 50;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .CookiesConfigModal {
    /* Modal styles */
    background-color: #fff;
    border-radius: 6px;
    max-height: 90vh;
    width: 85vw;
    max-width: 275px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 25px;
    box-shadow: 0px 10px 38px -10px hsla(206, 22%, 7%, 0.35), 0px 10px 20px -15px hsla(206, 22%, 7%, 0.2);
    outline: none;
  }
  
  /* Title and description */
  .CookiesConfigModal h2 {
    font-size: 0.85rem; /* Adjust as needed */
    color: #333; /* Adjust color */
    margin-bottom: 1rem; /* Adjust spacing */
    font-weight: normal;
  }
  
  .CookiesConfigModal p {
    font-size: 0.75rem; /* Adjust as needed */
    color: #666; /* Adjust color */
    margin-bottom: 1rem; /* Adjust spacing */
    margin-top: 20px;
  }
  
  /* Checkbox labels */
  .CookiesConfigModal label {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 0.75rem; /* Adjust spacing */
  }
  
  input[type="checkbox"] {
    /* Custom checkbox styles */
    appearance: none; 
    width: 1rem;
    height: 1rem;
    border: 2px solid #ccc; 
    border-radius: 0.25rem;
    background-color: #fff;
    cursor: pointer; 
  }

  input[disabled] {
    /* Custom checkbox styles */
    appearance: none; /* Remove default appearance */
    width: 1rem;
    height: 1rem;
    border: 2px solid #ccc; /* Example border color */
    border-radius: 0.25rem;
    background-color: #fff;
    cursor: not-allowed;
     /* Example background color */
  }
  
  /* Example of targeting checked state */
  input[type="checkbox"]:checked {
    background-color: #002b7a; /* Example checked background color */
    border-color: #002b7a; /* Example checked border color */
  }
  
  .CookiesConfigModal .text-caption {
    font-size: 0.75rem;
    color: #333; /* Adjust color */
    max-width: 100%; /* Ensure text wraps */
  }
  
  /* Button */
  .CookiesConfigModal button {
    width: 100%;
    background-color: #002b7a; /* Adjust background color */
    color: #fff; /* Adjust text color */
    border: none;
    border-radius: 9999px; /* Round button shape */
    padding: 0.5rem 1rem; /* Adjust padding */
    font-size: 0.875rem; /* Adjust font size */
    cursor: pointer;
    transition: background-color 0.2s ease;
    margin-top: 50px;
  }
  
  .CookiesConfigModal button:hover {
    background-color: #2c5282; /* Adjust hover background color */
  }