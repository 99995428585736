/* home.css */
.homePage {
  font-family: "Roboto";
  color: #333;
}

/* Styles for the intro section */
.introSection {
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.slogan {
  width: auto;
  height: auto;
  font-style: normal;
  color: #0052bd;
  font-size: 75px;
  letter-spacing: 0em;
  margin-bottom: 35px;
}

.companyLargeIntro {
  width: 808px;
  height: auto;
  max-width: 60%;
  min-width: 60%;
  font-style: normal;
  font-family: "Inter-Medium", "Inter", sans-serif;
  color: #828282;
  font-size: 16px;
  letter-spacing: 0em;
  line-height: 1.2;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 15px;
}

/* Styles for the image transitions section */
.imageTransitions {
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  display: flex;
  padding-top: 150px;
}

.imageTransitionContainer1,
.imageTransitionContainer2 {
  width: 449px;
  height: 536px;
  display: block;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 30px;
}

.imageTransitionContainer1 {
  background-image: url(../../media/fotosWeb/seleccion/_DSC5043.jpeg);
  margin-right: 50px;
  position: relative;
  right: 100px;
}

.imageTransitionContainer2 {
  background-image: url(../../media/fotosWeb/seleccion/_DSC5034.jpeg);
  position: relative;
  top: 220px;
  left: 50px;
}

/* Styles for the servicios section */
.servicios {
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  background-color: #ffffff;
  padding: 50px 0;
}

.servicios h2 {
  width: auto;
  height: auto;
  white-space: pre;
  font-weight: 600;
  font-style: normal;
  font-family: "Inter-SemiBold", "Inter", sans-serif;
  color: #0052bd;
  font-size: 43px;
  letter-spacing: 0em;
  line-height: 1.2;
  padding-left: 50px;
}
.servicios {
  padding: 20px;
}

.grid {
  display: grid;
  align-items: center;
  justify-items: center; 
  grid-template-columns: repeat(2, 1fr); /* Adjust as needed */
  gap: 20px;
  width: auto;
  
}


.card {
  border: 1px solid #ccc;
  padding: 20px;
  text-align: center;
  width: 80%;
  border-radius: 15px;
}

.open-modal-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 15px;
}




/* Modal styles */
.modal-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgb(255, 255, 255); 
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 95%;
  max-height: 80%;
  overflow: auto;
}

.services1-img1{
  background-image: url(../../media/fotosWeb/seleccion/078.JPG);
  padding-right: 150px
}

.services2-img1{
  background-image: url(../../media/fotosWeb/seleccion/IMG_2138.jpeg);
  padding-right: 150px
}

/* Make the modal visible when the state isModalOpen is true */
.modal-container.open {
  opacity: 1;
  visibility: visible;
}


.close-modal-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.close-modal-btn:hover {
  border-radius: 25%;
  background-color: #d0d0d0;
}

.services-img{
  height: 400px;
  display: block;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 30px;
}

.modal-text{
  margin: 25px;
  text-align: left;
}



.flashcardBox{
  margin: auto;
  width: 90%;
}
.generalCardSettings{
  width: 100%;
  height: 459px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  border-radius: 30px;
  background-color: rgb(0, 0, 0);

}

.generalCardSettings h2{
  width: auto; /* 425px */
  height: auto; /* 106px */
  white-space: pre;
  font-weight: 500;
  font-style: normal;
  font-family: "Roboto";
  color: #ffffff;
  font-size: 44px;
  letter-spacing: 0em;
  line-height: 1.2;
  margin: 45px;
}

.generalCardSettings button{
  box-sizing: border-box;
  width: 196px;
  height: 39px;
  display: block;
  overflow: hidden;
  border-radius: 20px;
  border: 2px solid #ffffff;
  background-color: transparent;
  color: #ffffff;
  margin-left: 45px;
  margin-top: -15px;
  cursor: pointer;
  font-size: 13px;
}

.generalCardSettings a{
  text-decoration: none;
}

.generalCardSettings button:hover{
  background-color: #f9f9f9;
  color: #242424;
  transition: ease-in-out 0.3s;
}

.InfoFlashcards{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px;
}

.flexFlashcardBox{
  width: 47%;
  height: 509px;
  display: flex;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 30px;
}

.proyectosBg {
  position: relative;
  background-image: url(../../media/fotosWeb/seleccion/UZGR4788.jpeg);
  overflow: hidden; /* Prevent the overlay from overflowing */
}

.proyectosBg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25); /* Adjust the alpha (last value) to control darkness */
}

.overlay-content {
  position: relative;
  z-index: 1; /* Bring the content above the overlay */
}




.workBg{
  background-image: url(../../media/fotosWeb/seleccion/078.JPG);
}

.empresaBg{
  background-image: url(../../media/fotosWeb/seleccion/_DSC5046.jpeg);
}

@media screen and (max-width: 960px) {
  .InfoFlashcards{
    flex-direction: column;
    width: 100%;
    margin: 0;
  }

  .generalCardSettings {
    height: 325px;
    margin-top: 15px;
    width: 348px;
  }

  .flexFlashcardBox {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 30px;
    display: flex;
    height: 509px;
    justify-content: space-around;
    overflow: hidden;
    width: 47%;
  }

  .proyectosBg .overlay-content h2, .servicios h2 {
    font-size: 32px;
  }

  .flashcardBox{
    width: 100%;
  }

  .WWUbox{
    width: auto;
    height: auto;
  }

  .imageTransitions {
    align-items: center;
    display: flex;
    height: 60vh;
    justify-content: center;
    overflow: hidden;
    padding-top: 150px;
  }

  .imageTransitionContainer1,
  .imageTransitionContainer2 {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 30px;
    display: block;
    height: 336px;
    overflow: hidden;
    width: 249px;
  }

  .imageTransitionContainer1 {
    margin-right: 50px;
    position: relative;
    right: -10px;
  }

  .imageTransitionContainer2 {
    left: -10px;
    position: relative;
    top: 220px;
  }

  .servicios h2 {
    font-size: 32px;
    margin-left: -20px;
  }

  .generalCardSettings h2 {
    width: auto;
    height: auto;
    white-space: pre;
    font-weight: 500;
    font-style: normal;
    font-family: "Roboto";
    color: #ffffff;
    font-size: 32px;
    letter-spacing: 0em;
    line-height: 1.2;
    margin: 45px;
  }

  
}

